import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import { getContentUrl } from '../../../utils/utils'
import styles from './PageGeneric.module.scss'

function PageGeneric({
  unitName,
  index,
  ...pageProps
}: any) {
  const { trackClick } = useContext(AnalyticsContext)
  const pageUrl = getContentUrl(pageProps)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div
      data-testid={`page-content-${index}`}
      className='p-sm-relative d-sm-flex flex-sm flex-sm-column'
    >
      <TrackImpression
        as='div'
        className='p-sm-absolute left-sm-8'
        key={pageProps.sys.id}
        linkedContentId={pageProps.sys.id}
        linkedContentUrl={pageUrl}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={index + 1}
        unitVariation='page_content'
        isContent
        linkText={pageProps.title}
      />
      <div className={`${styles.image} no-print`}>
        <div className='p-sm-relative rounded-sm-1 o-sm-hidden'>
          <div className='p-sm-relative' style={{ paddingTop: '75%' }}>
            <Image
              sizes='280px'
              className='of-sm-cover'
              fill
              src={pageProps.seo?.seoImage?.url}
              alt=''
            />
          </div>
        </div>
      </div>
      <div
        className='d-sm-flex flex-sm mt-sm-3'
      >
        <div
          className='flex-sm lh-condensed'
        >
          <h3 className='lc-sm-4'>
            <Link
              href={pageUrl}
              className='stretched-link primary-link no-underline f-sm-4 f-md-5'
              onClick={() => {
                trackClick({
                  clickType: 'text',
                  isContent: true,
                  linkText: pageProps.title,
                  linkedContentId: pageProps.sys.id,
                  linkedContentUrl: pageUrl,
                  unitName,
                  componentId: unitName,
                  unitLocation: 'inline',
                  positionInUnit: index + 1,
                  unitVariation: 'page_content',
                  parentComponentId: parentComponent?.sys.id,
                  positionInParentComponent: getPositionInParent(unitName)
                })
              }}
            >
              {pageProps.title}
            </Link>
          </h3>
        </div>
      </div>
    </div>
  )
}

export default PageGeneric
