import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGplay = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.8 16C28.8 23.0692 23.0692 28.8 16 28.8C8.93075 28.8 3.2 23.0692 3.2 16C3.2 8.93075 8.93075 3.2 16 3.2C23.0692 3.2 28.8 8.93075 28.8 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM13.9961 9.72735L22.0011 15.1847C22.533 15.5471 22.533 16.4529 22.0011 16.8153L13.9961 22.2726C13.4642 22.6347 12.8 22.1818 12.8 21.4573V10.5427C12.8 9.8182 13.4642 9.36535 13.9961 9.72735Z'
      />
    </svg>
  )
}

export default SVGplay
