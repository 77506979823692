import { Link } from '@/journey/Link'
import SVGsquiggle from '@fractal/primitives/SVGs/icons/squiggle'
import RichText from '@fractal/primitives/atoms/RichText'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import React, { useContext } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import styles from './ComponentMoreAboutUs.module.scss'
import { IComponentMoreAboutUs } from './ComponentMoreAboutUs.type'

function ComponentMoreAboutUs({
  title,
  sectionListCollection
}: IComponentMoreAboutUs) {
  const { pageLayout } = useContext(PageContext)

  return (
    <div
      className={`bg-static-midblueShade ${pageLayout.columnWidthLarge === 10
        ? sharedStyles.fullWidthLargeGrid10
        : sharedStyles.fullWidthLargeGrid12
      }`}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className='container grid'>
            <div className={`mt-sm-6 mb-sm-7 my-md-8 grid-col-lg-8
              grid-start-lg-3 grid-col-md-10 grid-start-md-1 grid-col-sm-12`}
            >
              <h2 className='text-center f-sm-2 f-md-6 mb-sm-5 mb-md-7 text-regular'>
                {title}
                <SVGsquiggle className='mt-sm-4 mx-sm-auto d-sm-block vicon fill-foreground-scarletTint' />
              </h2>
              {sectionListCollection?.items?.map((section, index) => (
                <div
                  key={`Componentmoreaboutus-section-${section.sys.id}`}
                  className={index < sectionListCollection.items.length - 1 ? 'mb-sm-7' : ''}
                >
                  <h3 className='f-sm-6 f-md-7 mb-sm-3'>{section.title}</h3>
                  {section.summary?.json && (
                    <div className='f-sm-3 f-md-5 mb-sm-5'>
                      <RichText richTextContent={section.summary.json} richTextLinks={section.summary.links} unstyled />
                    </div>
                  )}
                  {section.ctaLabel && (
                    <Link
                      className={`Btn Btn--shadow Btn--responsive no-underline ${styles.button}`}
                      href={section.ctaLink || '/'}
                      aria-label={`learn more about ${section.title}`}
                    >
                      {section.ctaLabel}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComponentMoreAboutUs
