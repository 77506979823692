import SVGexpert from '@fractal/primitives/SVGs/icons/expert'
import { Image } from '../Image'
import { IAvatarProps } from './Avatar.interface'

const fallbackImg = 'https://images.ctfassets.net/p0qf7j048i0q/4sZyFzW5hXsfMBTVoLsJRz/4ab85c22c58631d1cd991a241bc16399/logoU.svg'

function Avatar({
  src,
  alt = '',
  sizes = '52px',
  className = '',
  expert,
  sizeIcon = 32,
  ...props
}: IAvatarProps) {
  return (
    <div className='p-sm-relative w-sm-full h-sm-full'>
      <div
        className={`p-sm-relative circle-sm d-sm-block o-sm-hidden w-sm-full h-sm-full
        ${!src ? 'bg-static-darkblue' : ''}
      `}
        data-testid='avatar'
      >
        <Image
          src={src || fallbackImg}
          alt={alt}
          className={`fill-static-cream of-sm-cover ${className}`}
          fill
          sizes={sizes}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />

      </div>

      {!!expert
        && (
          <SVGexpert
            width={sizeIcon}
            height={sizeIcon}
            className='vicon p-sm-absolute right-sm-0 bottom-sm-0'
          />
        )}
    </div>
  )
}
export default Avatar
