import styles from './StyledButton.module.scss'

interface IStyledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

function StyledButton({ children, ...props }: IStyledButtonProps) {
  return (
    <button
      {...props}
      className={`${props.className} ${styles.styledButton} px-sm-5 py-sm-3 f-sm-3 f-md-4 pill-sm text-bold w-md-auto text-center text-foreground-darkblue`}
    >
      {children}
    </button>
  )
}

export default StyledButton
