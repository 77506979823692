import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import SVGlinkedin from '@/journey/Svgs/icons/linkedin'
import SVGtwitter from '@fractal/primitives/SVGs/socials/twitter'
import SVGfacebook from '@/journey/Svgs/icons/facebook'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { IComponentSocial } from './ComponentSocial.type'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import styles from './ComponentSocial.module.scss'

export default function ComponentSocial({
  sys,
  title,
  linkedin,
  twitter,
  facebook
}: IComponentSocial) {
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()
  const { pageLayout } = useContext(PageContext)

  if (!linkedin && !twitter && !facebook) return null
  const handleClick = (type, url) => {
    trackClick({
      clickType: 'button',
      buttonName: 'connect',
      buttonText: type,
      linkedContentUrl: url,
      isExternal: true,
      unitName: sys.id,
      componentId: sys.id,
      unitLocation: 'inline',
      parentComponentId: parentComponent?.sys.id,
      positionInParentComponent: getPositionInParent(sys.id)
    })
  }

  // eslint-disable-next-line max-len
  const pillClassName = 'w-sm-6 h-sm-6 bg-background-high-darkblue circle-sm d-sm-flex flex-sm-justify-center flex-sm-align-center'

  return (
    <div
      className={`${pageLayout.columnWidthLarge === 10 ? sharedStyles.grid10 : 'grid'
      } mt-sm-5 mt-md-6 mb-sm-6 mb-md-7`}
      data-testid='body-text-section'
    >
      <div
        className={
          pageLayout.columnWidthLarge === 10
            ? 'grid-col-sm-10 grid-start-lg-3 grid-col-lg-6'
            : 'grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-start-lg-4 grid-col-lg-6'
        }
      >
        <TrackImpression
          unitName={sys.id}
          unitLocation='inline'
          componentId={sys.id}
        />
        {title && (
          <p className='f-sm-3 f-md-4 text-bold mb-sm-3 mb-md-4'>
            {title}
          </p>
        )}
        <ul className={`list-reset m-sm-0 d-sm-flex ${styles.horizontalList}`}>
          {linkedin && (
            <li>
              <a
                onClick={() => handleClick('linkedin', linkedin)}
                href={linkedin}
                className={pillClassName}
              >
                <SVGlinkedin className='fill-static-white' />
              </a>
            </li>
          )}
          {twitter && (
            <li>
              <a
                onClick={() => handleClick('twitter', twitter)}
                href={twitter}
                className={pillClassName}
              >
                <SVGtwitter className='fill-static-white' />
              </a>
            </li>
          )}
          {facebook && (
            <li>
              <a
                onClick={() => handleClick('facebook', facebook)}
                href={facebook}
                className={pillClassName}
              >
                <SVGfacebook className='fill-static-white' />
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
