import { Image } from '@/journey/Image'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGwunder from '@fractal/primitives/SVGs/logos/wunder'
import { useContext, useEffect, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { TrackImpression } from '../TrackImpression'

function WunderSmartLink({
  href, unitLocation, unitName, label
}: any) {
  const { trackClick } = useContext(AnalyticsContext)
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => setIsBrowser(true), [])

  if (!isBrowser) return null

  if (isIOS) {
    return (
      <>
        <TrackImpression
          unitName={unitName}
          unitLocation={unitLocation}
          isExternal
          buttonText={label}
          linkedContentUrl={href}
          componentId={unitName}
          buttonName='download_app'
        />
        <a
          data-testid='app-store-link'
          href='https://apps.apple.com/app/apple-store/id1552485748?pt=122591629&ct=traditional_feed&mt=8'
          onClick={() => {
            trackClick({
              clickType: 'button',
              componentId: unitName,
              isExternal: true,
              buttonName: 'download_app',
              buttonText: label,
              linkedContentUrl: href,
              unitName,
              unitLocation
            })
          }}
        >
          <Image
            width={163}
            height={52}
            sizes='200px'
            className='of-sm-cover'
            alt={label}
            // eslint-disable-next-line max-len
            src='https://images.ctfassets.net/p0qf7j048i0q/orbuUCOubQSCdMmbTACTm/002dd5828b298a9ddeac785fef9fca1a/appstore.svg'
          />
        </a>
      </>
    )
  }
  return (
    <>
      <TrackImpression
        unitName={unitName}
        unitLocation={unitLocation}
        buttonText={label}
        linkedContentUrl={href}
        componentId={unitName}
        buttonName='learn_more'
      />
      <a
        data-testid='wunder-link'
        href={href}
        className='Btn Btn--shadow Btn--small no-underline'
        onClick={() => {
          localStorage.setItem('wunder_smart_cta', 'traditional_feed')
          trackClick({
            clickType: 'button',
            componentId: unitName,
            buttonName: 'learn_more',
            buttonText: label,
            linkedContentUrl: href,
            unitName,
            unitLocation
          })
        }}
      >
        <SVGwunder
          width={20}
          height={14}
          className='fill-foreground-scarlet mr-sm-2'
        />
        {label}
      </a>
    </>
  )
}

export default WunderSmartLink
