import { RichText } from '@fractal/primitives/atoms/RichText'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext, useEffect, useState } from 'react'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import styles from './ComponentBodyText.module.scss'
import { IComponentBodyText } from './ComponentBodyText.type'
import Truncate from './Truncate'

const componentsWithoutMarginBottom = ['ComponentSocial']

export default function ComponentBodyText({
  sys,
  body,
  truncate
}: IComponentBodyText) {
  const [showBody, setShowBody] = useState(!truncate)
  const { pageLayout } = useContext(PageContext)
  const { getPositionInParent, entries } = usePageComponentsContext()

  useEffect(() => {
    setShowBody(!truncate)
  }, [truncate])

  if (!body?.json) return null

  const id = `page-body-text-${sys.id}`
  const bodyClass = `${styles.body} ${showBody ? '' : styles.truncate}`
  const positionInParentComponent = getPositionInParent(sys.id)
  let removeMarginBottom = false
  if (positionInParentComponent && entries.length > positionInParentComponent) {
    removeMarginBottom = componentsWithoutMarginBottom.includes(entries[positionInParentComponent].typename)
  }

  return (
    <div
      className={`${pageLayout.columnWidthLarge === 10 ? sharedStyles.grid10 : 'grid'
      } mt-sm-5 mt-md-6 ${removeMarginBottom ? '' : 'mb-sm-6 mb-md-7'}`}
      data-testid='body-text-section'
    >
      <TrackImpression
        unitName={sys.id}
        unitLocation='header'
        componentId={sys.id}
        className={
          pageLayout.columnWidthLarge === 10
            ? 'grid-col-sm-10 grid-start-lg-3 grid-col-lg-6'
            : 'grid-col-sm-12 grid-start-md-2 grid-col-md-10 grid-start-lg-4 grid-col-lg-6'
        }
      >
        <div id={id} className={`f-sm-3 f-md-5 ${bodyClass}`}>
          <RichText richTextContent={body.json} richTextLinks={body.links} unstyled />
        </div>
        {!showBody && (
          <Truncate
            selector={`#${id}>div`}
            onContinue={() => setShowBody(true)}
            unitName={sys.id}
          />
        )}
      </TrackImpression>
    </div>
  )
}
