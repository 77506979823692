import { useApolloClient } from '@apollo/client'
import { localeSubPathMap } from '@common/utils/language'
import { LanguageContext } from '@context/LanguageContext'
import { mapArticles } from 'hooks/useTrendingArticles/helpers'
import get from 'lodash/get'
import React, {
  useContext, useEffect, useRef, useState
} from 'react'
import { GetSimplifiedSectionV2 } from 'services/hub/queries'
import fetchTaggedContent from 'utils/fetchTaggedContent'
import { IContentProviderContext } from './ContentContext.type'

interface Section {
  type: string
  title: string
  requireInternalTags: string[]
  excludeInternalTags: string[]
}

export const ContentProviderContext = React.createContext<IContentProviderContext>({
  registerFilter: (id, filters) => {
  },
  unregisterFilter: (id) => {
  },
  isSectionLoading: (id) => false,
  registerComponent: (props) => {
  },
  unregisterComponent: (id) => {
  },
  getData: (id) => {
  }
})

const FILTER_EXTERNAL_TAGS = (item) => item.__typename === 'ExternalTag'
const FILTER_INTERNAL_TAGS = (item) => item.__typename === 'InternalTag'
const MAP_TO_IDS = (item) => item?.sys?.id

export function ContentProvider({ children }) {
  const apolloClient = useApolloClient()
  const { language } = useContext(LanguageContext)
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState({})
  const [components, setComponents] = useState([])
  const [componentsData, setComponentsData] = useState({})
  const loadedContentIds = useRef([])

  const loadComponentData = async (component) => {
    // if contains external filter and it is registered, get its selected tags
    const externalFilterTags = filters[component.externalFilter?.sys.id] || []

    // get all external tags from the filter and the ones linked to the component
    const externalTags = [
      ...externalFilterTags.filter(FILTER_EXTERNAL_TAGS),
      ...component.filterByCollection.items.filter(FILTER_EXTERNAL_TAGS)
    ].map(MAP_TO_IDS)

    // do the same for internal tags
    const internalTags = [
      ...externalFilterTags.filter(FILTER_INTERNAL_TAGS),
      ...component.filterByCollection.items.filter(FILTER_INTERNAL_TAGS)
    ].map(MAP_TO_IDS)

    // save the handpicked ids
    loadedContentIds.current.push(
      ...component.contentListCollection.items.map(MAP_TO_IDS)
    )

    // if all required data is present, load data from the API
    let apiContent = []
    if (
      (externalTags.length || internalTags.length)
      && component.contentLimit
      && component.contentType?.length
    ) {
      const sections: Section[] = component.contentType.map((type): Section => ({
        type,
        title: component.id,
        requireInternalTags: internalTags,
        excludeInternalTags: []
      }))

      const taggedContent = await fetchTaggedContent(
        {
          sections,
          extraInternalTags: [],
          externalTags,
          excludedEntries: loadedContentIds.current,
          userClassifications: [],
          userClassificationsWeight: 0,
          skip: 0,
          limit: component.contentLimit,
          hardLimit: true,
          locale: localeSubPathMap.get(language)
        }
      )

      const podcastEpisodeIds = []
      const articleIds = []

      taggedContent.forEach((params) => {
        if (params.type === 'PodcastEpisode') {
          podcastEpisodeIds.push(...params.items.map((item) => item.entryId))
        }
        if (params.type === 'Article') {
          articleIds.push(...params.items.map((item) => item.entryId))
        }
      })

      const { data } = await apolloClient.query({
        query: GetSimplifiedSectionV2,
        variables: { articleIds, podcastEpisodeIds, podcastIds: [] },
        context: { clientName: 'contentFul' }
      })

      apiContent = [
        ...mapArticles(data.Article.items),
        ...data.PodcastEpisode.items.map((podcast) => ({
          ...podcast,
          podcast: get(podcast, 'linkedFrom.podcastCollection.items[0]', null)
        }))
      ]
      loadedContentIds.current.push(...apiContent.map(MAP_TO_IDS))
    }

    // set the new data to the component state
    const newComponentData = {
      [component.id]: [...component.contentListCollection.items, ...apiContent]
    }
    setComponentsData((prev) => ({ ...prev, ...newComponentData }))
    setLoading((prev) => ({ ...prev, [component.id]: false }))
  }

  useEffect(() => {
    if (!components.length) return

    // when a new filter is set, we load data to all components
    // so, set all of them as loading = true
    setLoading((prev) => {
      const newLoadingState = {}
      for (const componentId in prev) {
        newLoadingState[componentId] = true
      }
      return newLoadingState
    })

    // save the ids of the loaded content and exclude them from the next request
    loadedContentIds.current = []

    async function loadDataInSequence() { // this could be asynchronous
      for (const component of components) {
        await loadComponentData(component)
      }
    }

    loadDataInSequence()
  }, [filters])

  const registerComponent = (componentVariabled) => {
    setComponents((prev) => [...prev, componentVariabled])
    loadComponentData(componentVariabled)
  }

  const unregisterComponent = (id) => {
    const newComponents = [...components]
    newComponents.splice(
      components.findIndex((comp) => comp.id === id),
      1
    )
    setComponents(newComponents)
  }

  const getData = (id) => componentsData[id] || []

  const registerFilter = (id, filters) => {
    const duplicatesRemoved = filters?.reduce((acc, filter) => {
      if (acc.some((f) => f.sys.id === filter.sys.id)) return acc
      return [...acc, filter]
    }, [])
    setFilters((prev) => ({ ...prev, [id]: duplicatesRemoved }))
  }

  const unregisterFilter = (id) => {
    const newFilters = { ...filters }
    delete newFilters[id]
    setFilters(newFilters)
  }

  const isSectionLoading = (id) => !!loading[id]

  return (
    <ContentProviderContext.Provider
      value={{
        registerFilter,
        unregisterFilter,
        isSectionLoading,
        registerComponent,
        unregisterComponent,
        getData
      }}
    >
      {children}
    </ContentProviderContext.Provider>
  )
}
