import * as React from 'react'
import { ISVGsBorderProps } from '../types'

const SVGborderTriangleDown = ({
  pathfillColor,
  id,
  className = '',
  height = 7,
  ...props
}: ISVGsBorderProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height={height}
      aria-hidden='true'
      focusable='false'
      data-testid='border-triangleDown-svg'
      className={className || `d-sm-block`}
      {...props}
    >
      <defs>
        <pattern
          id={id}
          patternUnits='userSpaceOnUse'
          width='20'
          height={height}
          fill='none'
        >
          <path
            clipRule='evenodd'
            d='M5.92402e-07 7.5976e-05L10 6.77637L20 7.77245e-05L5.92402e-07 7.5976e-05Z'
            className={`fill-${pathfillColor}`}
          />
        </pattern>
      </defs>
      <rect width='100%' height='100%' fill={`url(#${id})`} />
    </svg>
  )
}

export default SVGborderTriangleDown
