import { AnalyticsContext } from '@context/AnalyticsContext'
import useTranslation from 'app/i18n/client'
import { ContentProviderContext } from 'components/dynamic-page/providers/ContentContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import React, { useContext, useEffect, useState } from 'react'
import MapContent from '../../shared/components/MapContent'
import SectionHeader from '../../shared/components/SectionHeader'
import StyledButton from '../../shared/components/StyledButton'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'
import styles from './ComponentCardList.module.scss'
import { IComponentCardList } from './ComponentCardList.type'

export function ComponentCardList(props: IComponentCardList) {
  const {
    sys,
    title,
    initialContentLimit,
    contentLimit,
    contentType,
    filterByCollection,
    contentListCollection,
    externalFilter
  } = props
  const step = 3
  const [limit, setLimit] = useState(initialContentLimit)
  const { trackClick } = useContext(AnalyticsContext)
  const {
    registerComponent, unregisterComponent, getData, isSectionLoading
  } = useContext(ContentProviderContext)
  const { t } = useTranslation()
  const resourceList = getData(sys.id)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  useEffect(() => {
    registerComponent({
      id: sys.id,
      contentLimit,
      contentType,
      filterByCollection,
      contentListCollection,
      externalFilter
    })

    return () => {
      unregisterComponent(sys.id)
    }
  }, [
    sys.id,
    contentLimit,
    contentType,
    filterByCollection,
    contentListCollection,
    externalFilter
  ])

  const handleLoadMore = () => {
    trackClick({
      clickType: 'button',
      buttonName: 'load_more',
      buttonText: t('load-more'),
      unitName: sys.id,
      componentId: sys.id,
      unitLocation: 'inline',
      parentComponentId: parentComponent?.sys.id,
      positionInParentComponent: getPositionInParent(sys.id)
    })

    const newLimit = limit + step
    setLimit(newLimit)
  }

  return (
    <>
      <TrackImpression
        unitName={sys.id}
        unitLocation='inline'
        componentId={sys.id}
      />
      <div
        data-testid='component-card-list'
        className={sharedStyles.sectionMargin}
      >
        {title && <SectionHeader title={title} isLoading={isSectionLoading(sys.id)} />}

        <ul className={`grid list-reset m-sm-0 ${resourceList.every((content) => content.typename === 'Person') ? styles.bioList : styles.list}`}>
          {resourceList.map((content, index) => {
            if (index >= limit) return null

            return (
              <li
                key={content.sys.id}
                className='grid-col-sm-12 grid-col-md-6 grid-col-lg-4 d-sm-flex mx-sm-n3 mx-md-0'
              >
                <MapContent
                  content={content}
                  index={index}
                  unitName={sys.id}
                  stackOnMobile
                />
              </li>
            )
          })}
        </ul>

        {limit < resourceList.length && (
          <div className='mt-sm-5 text-center no-print'>
            <StyledButton
              onClick={handleLoadMore}
              data-testid='card-list-explore-more'
            >
              <TrackImpression
                unitName={sys.id}
                unitLocation='inline'
                componentId={sys.id}
                buttonName='load_more'
                buttonText={t('load-more')}
              />
              {t('load-more')}
            </StyledButton>
          </div>
        )}
      </div>
    </>
  )
}

export default ComponentCardList
