import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGplay from '@fractal/primitives/SVGs/icons/play'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import { getContentUrl } from '../../../utils/utils'
import styles from './ArticleContent.module.scss'

function ArticleContent({
  isVideo,
  unitName,
  index,
  stackOnMobile,
  ...article
}: any) {
  const { trackClick } = useContext(AnalyticsContext)
  const fallBackThumbnail = isVideo
    ? 'https://images.ctfassets.net/p0qf7j048i0q/6mj7uXz7shWi9gjrIK5nBb/a6adfe94a0e3ce06d5431e14251c148a/anna-thumb.png'
    // eslint-disable-next-line max-len
    : 'https://images.ctfassets.net/p0qf7j048i0q/hJEgpmjWW4RhB03AdHmpq/8e08ba4df94c7f59bb2fa91104ca9815/empty_article.svg'

  const articleUrl = getContentUrl(article)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div
      data-testid={`article-content-${index}`}
      className={`p-sm-relative d-sm-flex flex-sm
        ${stackOnMobile ? 'flex-sm-row-reverse flex-md-column' : 'flex-sm-column'}
      `}
    >
      <TrackImpression
        as='div'
        className='p-sm-absolute left-sm-8'
        key={article.sys.id}
        linkedContentId={article.sys.id}
        linkedContentUrl={articleUrl}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={index + 1}
        unitVariation='article_content'
        isContent
        linkText={article.title}
      />
      <div className={`${styles.image} no-print`}>
        <div className='p-sm-relative rounded-sm-1 o-sm-hidden'>
          {isVideo && (
            <SVGplay
              width={50}
              height={50}
              className={`vicon mr-sm-3 fill-static-white ${styles.positionIcon}`}
            />
          )}
          <div className='p-sm-relative' style={{ paddingTop: '75%' }}>
            <Image
              sizes='280px'
              className='of-sm-cover'
              fill
              src={
                article.thumbnail?.url
                || article.heroImage
                || fallBackThumbnail
              }
              alt=''
            />
          </div>
        </div>
      </div>
      <div
        className={`d-sm-flex flex-sm
          ${stackOnMobile ? 'flex-sm-column flex-md-row mt-md-3' : 'mt-sm-3'}
        `}
      >
        <div
          className={`flex-sm lh-condensed ${stackOnMobile ? 'mb-sm-3 mb-md-0' : ''}`}
        >
          <h3 className='lc-sm-4'>
            <Link
              href={articleUrl}
              className='stretched-link primary-link no-underline f-sm-4 f-md-5'
              onClick={() => {
                trackClick({
                  clickType: 'text',
                  isContent: true,
                  linkText: article.title,
                  linkedContentId: article.sys.id,
                  linkedContentUrl: articleUrl,
                  unitName,
                  componentId: unitName,
                  unitLocation: 'inline',
                  positionInUnit: index + 1,
                  unitVariation: 'article_content',
                  parentComponentId: parentComponent?.sys.id,
                  positionInParentComponent: getPositionInParent(unitName)
                })
              }}
            >
              {article.title}
            </Link>
          </h3>
        </div>
      </div>
    </div>
  )
}

export default ArticleContent
