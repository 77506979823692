import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGcaretRight = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
      data-testid='caretRight'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.508 43L16 39.5L31.029 24.525L16 9.501L19.508 6L34.535 20.973C36.485 22.912 36.489 26.074 34.544 28.021L19.508 43Z'
      />
    </svg>
  )
}

export default SVGcaretRight
