import { Image } from '@/journey/Image'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import { useContext } from 'react'
import { IExternalTag } from '../../../types/ExternalTag.type'
import styles from './ExternalTagContent.module.scss'

function ExternalTagContent({
  unitName,
  index,
  ...tag
}: IExternalTag & { unitName: string; index: number }) {
  const { trackClick } = useContext(AnalyticsContext)
  const {
    sys, title, thumbnail, externalDescription
  } = tag
  const topicUrl = getContentUrl(tag, 'Topic')
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div
      data-testid={`tag-content-${index}`}
      className='p-sm-relative w-sm-full bg-background-low-white rounded-sm-1 border-shadow'
    >
      <TrackImpression
        className=''
        unitName={unitName}
        componentId={unitName}
        unitLocation='inline'
        positionInUnit={index + 1}
        isContent
        linkText={title}
        linkedContentId={sys.id}
        linkedContentUrl={topicUrl}
      >
        <div
          className={`${styles.imageContainer} p-sm-relative w-sm-full text-center no-print`}
        >
          <Image
            src={thumbnail?.url}
            alt=''
            sizes='254px'
            fill
            className='of-sm-cover'
          />
        </div>
        <div className=' p-sm-4 p-md-5'>
          <h3 className='f-sm-5 f-md-6 lh-condensed mb-sm-3'>
            <Link
              className='primary-link stretched-link'
              href={topicUrl}
              onClick={() => {
                trackClick({
                  clickType: 'text',
                  isContent: true,
                  linkText: title,
                  linkedContentId: sys.id,
                  linkedContentUrl: topicUrl,
                  unitName,
                  componentId: unitName,
                  unitLocation: 'inline',
                  positionInUnit: index + 1,
                  parentComponentId: parentComponent?.sys.id,
                  positionInParentComponent: getPositionInParent(unitName)
                })
              }}
            >
              {title}
            </Link>
          </h3>
          <p className='f-sm-3 f-md-4 lc-sm-5'>{externalDescription}</p>
        </div>
      </TrackImpression>
    </div>
  )
}

export default ExternalTagContent
