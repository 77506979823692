import { useEffect, useRef, useState } from 'react'
import { useMedia } from 'the-platform'
import SVGcaretLeft from '../Svgs/icons/caretLeft'
import SVGcaretRight from '../Svgs/icons/caretRight'
import { ICarouselProps } from './Carousel.interface'
import styles from './Carousel.module.scss'

function Carousel({ slides, className, ...props }: ICarouselProps) {
  const hlistRef = useRef<HTMLUListElement>(null)
  const [activePage, setActivePage] = useState(0)
  const idCarousel = props['data-testid'] || 'carousel'
  const [pageStarts, setPageStarts] = useState([])
  const isDesktop = useMedia({ minWidth: 1024 })
  const isMobile = useMedia({ maxWidth: 767 })

  useEffect(() => {
    // Split slides into pages based on the viewport size
    const step = isDesktop ? 3 : isMobile ? 1 : 2
    const starts = []
    for (let i = 0; i < slides.length; i += step) {
      starts.push(i)
    }
    setPageStarts(starts)
  }, [isMobile, isDesktop, slides.length])

  useEffect(() => {
    if (!hlistRef.current) return
    if (pageStarts.length < 2) return

    // the observer updates the 'active' state with the index of the card in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elementIndex = parseInt(
              entry.target.getAttribute('data-slide')
            )
            const elementsPerPage = pageStarts[1] - pageStarts[0]
            const page = Math.floor(elementIndex / elementsPerPage)
            setActivePage(page)
          }
        })
      },
      { threshold: [1] }
    )

    // add all cards to the observer
    hlistRef.current.querySelectorAll('li[data-slide]').forEach((element) => {
      observer.observe(element)
    })

    return () => {
      // disconnect the observer when the component dies or the slides changes
      observer?.disconnect()
      // sets the first card in view when the 'slides' prop changes
      // it ensures the carousel on the page the user is navigating to,
      // will display the first card
      if (hlistRef.current) {
        hlistRef.current.scrollLeft = 0
      }
      setActivePage(0)
    }
  }, [hlistRef.current, pageStarts])

  // Focus on the first item visible in the row that is now showed after a scroll
  const focusFirstVisibleItem = (page) => {
    if (!hlistRef.current) return
    setTimeout(() => {
      const elementIndex = pageStarts[page]
      const focusableChild = hlistRef.current
        .querySelector(`li[data-slide='${elementIndex}']`)
        .querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        ) as any
      focusableChild?.focus()
    }, 400)
  }

  // left and right keys to control the carousel
  const handleKeyPress = (event) => {
    if (event.key === 'ArrowLeft' && activePage > 0) {
      event.preventDefault()
      moveToPage(activePage - 1)
      focusFirstVisibleItem(activePage - 1)
    }
    if (event.key === 'ArrowRight' && activePage < pageStarts.length - 1) {
      event.preventDefault()
      moveToPage(activePage + 1)
      focusFirstVisibleItem(activePage + 1)
    }
  }

  // Scroll the content to the page requested
  const moveToPage = (newPageNumber) => {
    const elementIndex = pageStarts[newPageNumber]
    hlistRef.current
      .querySelector(`li[data-slide='${elementIndex}']`)
      .scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      })
    setActivePage(newPageNumber)
  }

  return (
    <div className='p-sm-relative' data-testid={idCarousel} {...props}>
      <div className={`w-sm-full p-sm-relative ${className ?? ''}`}>
        <ul
          ref={hlistRef}
          onKeyDown={handleKeyPress}
          tabIndex={0}
          className={`${styles.slides} w-sm-full list-reset p-sm-relative d-sm-flex flex-sm-nowrap flex-sm-align-stretch mr-sm-1`}
        >
          {slides.map((slide, index) => (
            <li
              className={`${styles.slide} mb-sm-1 col-sm-9 col-md-5 col-lg-4 p-sm-relative`}
              data-slide={index}
              aria-hidden={activePage === index ? 'false' : 'true'}
              key={index}
            >
              {slide}
            </li>
          ))}
        </ul>
      </div>

      <div
        className={`d-sm-flex ${styles.controls} flex-sm-align-center no-print`}
      >
        {pageStarts.length > 1 && (
          <>
            <div className='w-sm-5 w-lg-6 no-print'>
              <button
                className={`${styles.controlsButton} ${activePage === 0 || activePage === -1
                  ? 'd-sm-none'
                  : 'd-sm-inline-flex'
                  } Btn-link fill-svg text-foreground-scarletTint hover-text-foreground-scarletLite float-sm-left left-lg-n7`}
                aria-label='previous slide'
                data-testid={`${idCarousel}-previous`}
                onClick={() => moveToPage(activePage - 1)}
              >
                <SVGcaretLeft
                  height='50'
                  width='50'
                  className='w-sm-4 w-lg-6 h-sm-4 h-lg-6 fill-foreground-scarletTint'
                />
              </button>
            </div>

            <ul
              className='list-reset d-sm-flex flex-sm-justify-center text-static-scarletLite no-print'
              keyboard-select-mode='focus'
            >
              {pageStarts.map((value, index) => (
                <li
                  data-testid={`${idCarousel}-indicator-${index}`}
                  key={index}
                >
                  <button
                    className={`mx-sm-1 h-sm-3 w-sm-3 d-sm-block Btn-link ${styles.carouselIndicator}`}
                    aria-label={`go to slide ${index + 1}`}
                    onClick={() => moveToPage(index)}
                    data-testid={`${idCarousel}-dot-${index}`}
                  >
                    <span
                      className={`w-sm-2 h-sm-2 d-sm-block circle-sm ${activePage === index
                        ? 'bg-static-scarletTint'
                        : 'bg-static-scarletLite'
                        }`}
                    />
                  </button>
                </li>
              ))}
            </ul>
            <div className='w-sm-5 w-lg-6 no-print'>
              <button
                className={`${styles.controlsButton} ${activePage === pageStarts.length - 1
                  ? 'd-sm-none'
                  : 'd-sm-inline-flex'
                  } Btn-link fill-svg text-foreground-scarletTint hover-text-foreground-scarletTint float-sm-right right-lg-n7`}
                aria-label='next slide'
                data-testid={`${idCarousel}-next`}
                onClick={() => moveToPage(activePage + 1)}
              >
                <SVGcaretRight
                  height='50'
                  width='50'
                  className='w-sm-4 w-lg-6 h-sm-4 h-lg-6 fill-foreground-scarletTint'
                />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
export default Carousel
