import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGsquiggle = ({
  className = '',
  width = '50',
  height = '7',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 50 7'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M29.9998 0C33.1927 0 34.8781 1.35127 36.1572 2.37671L36.1968 2.40847C37.4129 3.38321 38.2258 4.00761 39.9999 4.00761C41.774 4.00761 42.5869 3.38321 43.803 2.40847L43.8427 2.37671C45.1217 1.35127 46.8071 0 50 0V2.99235C48.2259 2.99235 47.413 3.61675 46.1969 4.5915L46.1573 4.62325C44.8782 5.64869 43.1928 6.99997 39.9999 6.99997C36.807 6.99997 35.1216 5.64869 33.8426 4.62325L33.8029 4.5915C32.5869 3.61684 31.7741 2.99247 30.0003 2.99235C28.2262 2.99235 27.4133 3.61679 26.1972 4.59153L26.1575 4.62329C24.8785 5.64873 23.1931 7 20.0002 7C16.8073 7 15.1219 5.64873 13.8428 4.62329L13.8032 4.59153C12.5871 3.61679 11.7742 2.99239 10.0001 2.99239C8.22599 2.99239 7.41309 3.61679 6.19696 4.59153L6.15735 4.62329C4.87831 5.64873 3.19287 7 0 7V4.00765C1.77411 4.00765 2.58701 3.38325 3.80314 2.4085L3.84275 2.37675C5.12179 1.35131 6.80723 3.47204e-05 10.0001 3.47204e-05C13.193 3.47204e-05 14.8784 1.35131 16.1574 2.37675L16.1971 2.4085C17.4132 3.38325 18.2261 4.00765 20.0002 4.00765C21.7743 4.00765 22.5872 3.38325 23.8033 2.4085L23.8429 2.37675L23.843 2.3767C25.122 1.35132 26.8073 0.000138115 29.9998 0Z' />
    </svg>
  )
}

export default SVGsquiggle
