import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { AnalyticsContext } from '@context/AnalyticsContext'
import { Avatar } from '@/journey/Avatar'
import { Link } from '@/journey/Link'
import { ComponentType, Fragment, useContext } from 'react'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import SVGarrowLeft from '@/journey/Svgs/icons/arrowLeft'
import dynamic from 'next/dynamic'
import styles from './ComponentBioHeader.module.scss'
import { IComponentBioHeader } from './ComponentBioHeader.type'
import sharedStyles from '../../shared/styles/DynamicComponents.module.scss'

const LeaderHeaderShape = dynamic(() => import('./HeaderShape/LeaderHeaderShape'), { ssr: false })
const ExpertHeaderShape = dynamic(() => import('./HeaderShape/ExpertHeaderShape'), { ssr: false })
const ContributorHeaderShape = dynamic(() => import('./HeaderShape/ContributorHeaderShape'), { ssr: false })

export default function ComponentBioHeader({
  sys,
  title,
  expertise,
  avatar,
  role,
  backButtonLink,
  backButtonLabel,
  bgImage
}: IComponentBioHeader) {
  const { pageLayout } = useContext(PageContext)
  const { trackClick } = useContext(AnalyticsContext)
  const unitname = 'bioheader'

  let HeaderShape: ComponentType<any> = Fragment
  if (bgImage === 'Leader') HeaderShape = LeaderHeaderShape
  if (bgImage === 'Expert') HeaderShape = ExpertHeaderShape
  if (bgImage === 'Contributor') HeaderShape = ContributorHeaderShape

  return (
    <div data-testid='bio-header' className='mt-sm-5'>
      {backButtonLabel && backButtonLink && (
        <div className='container'>
          <TrackImpression
            unitName={unitname}
            componentId={sys.id}
            unitLocation='header'
            linkedContentUrl={backButtonLink}
            className={
              pageLayout.columnWidthLarge === 10
                ? sharedStyles.fullWidthLargeGrid10
                : sharedStyles.fullWidthLargeGrid12
            }
          >
            <Link
              aria-label={backButtonLabel}
              href={backButtonLink}
              className='ml-sm-4 ml-lg-5 mb-sm-5 mb-lg-2 d-sm-flex flex-sm-align-center no-underline'
              onClick={() => {
                trackClick({
                  clickType: 'button',
                  buttonName: 'back',
                  buttonText: backButtonLabel,
                  linkedContentUrl: backButtonLink,
                  unitName: sys.id,
                  componentId: sys.id,
                  unitLocation: 'header'
                })
              }}
            >
              <SVGarrowLeft className='vicon' />
              <span className='ml-sm-3 f-sm-3'>{backButtonLabel}</span>
            </Link>
          </TrackImpression>
        </div>
      )}
      <TrackImpression
        unitName={unitname}
        componentId={sys.id}
        unitLocation='header'
      />
      <div
        className={`p-sm-absolute top-sm-0 left-sm-0 w-sm-full z-index-n1 ${styles.animateShape}`}
      >
        <div className='d-md-none w-sm-full'>
          <HeaderShape size='sm' />
        </div>
        <div className='d-sm-none d-md-block'>
          <HeaderShape size='md' />
        </div>
      </div>
      <div
        className='container grid text-center'
        data-testid='bio-header-section'
      >
        <div className='grid-col-sm-12 grid-col-md-10 grid-start-md-2 grid-col-lg-6 grid-start-lg-4'>
          <div className='w-sm-8 h-sm-8 mx-sm-auto'>
            <Avatar
              data-testid='bio-image'
              src={avatar?.url}
              alt=''
              sizes='136px'
              expert={role === 'Expert'}
            />
          </div>

          {title && (
            <h1
              id='target-skip-to-content'
              className='mt-sm-4 mt-md-5 f-sm-8 f-md-10 text-bold'
            >
              {title}
            </h1>
          )}
          {expertise && (
            <h2
              id='target-skip-to-content'
              className='mt-sm-3 mt-md-4 f-sm-4 f-md-5 text-bold'
            >
              {expertise}
            </h2>
          )}
        </div>
      </div>
    </div>
  )
}
