import * as React from 'react'
import { ISVGsProps } from '../types'

//WunderLogo
const SVGwunder = ({
  className = '',
  width = '120',
  height = '82',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 120 82'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M0.0388849 23.6645C0.725123 39.149 7.37399 53.7679 18.5939 64.4615C29.8138 75.1551 44.735 81.0945 60.2345 81.0366C75.7341 80.9787 90.6105 74.928 101.75 64.1509C112.89 53.3738 119.429 38.7057 120 23.2165H119.871C122.28 -5.39495 82.4188 -8.28198 79.8802 19.1448C77.6602 -5.9624 42.976 -6.01217 39.8799 18.4081C39.2527 10.5733 35.5494 6.46179 29.9246 2.62901C26.9307 0.919048 23.545 0.0133754 20.0971 0.00014702C16.6492 -0.0130814 13.2567 0.866586 10.2497 2.55352C7.24265 4.24046 4.72361 6.67719 2.93776 9.62653C1.1519 12.5759 0.160067 15.9373 0.0587997 19.3837C-0.0606636 20.0806 0.0388849 23.6645 0.0388849 23.6645Z' />
    </svg>
  )
}

export default SVGwunder
