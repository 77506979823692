import { Avatar } from '@/journey/Avatar'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGclose from '@fractal/primitives/SVGs/icons/close'
import SVGexpert from '@fractal/primitives/SVGs/icons/expert'
import RichText from '@fractal/primitives/atoms/RichText'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { useMedia } from 'the-platform'
import { IWunderTestimonial } from '../../../types/WunderTestimonial.type'
import WunderSmartLink from '../../WunderSmartLink'
import styles from './WunderTestimonialContent.module.scss'

const avatarColors = [
  'scarletTint',
  'ochre',
  'midblueLite',
  'scarletLite',
  'peach'
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

function WunderCard({
  full,
  wunder,
  onClick
}: {
  full: boolean
  onClick?: any
  wunder: IWunderTestimonial
}) {
  const initials = wunder?.name
    .split(' ')
    .reduce((prev, curr) => `${prev}${curr.charAt(0)}`, '')
  const randomNumber = getRandomInt(avatarColors.length)
  const colorClass = useRef(avatarColors[randomNumber])
  const { t } = useTranslation()

  return (
    <div className={`d-sm-flex flex-sm-column p-sm-relative flex-sm rounded-sm-1 o-sm-hidden 
      bg-background-high-white border-sm-1 border-background-high-scarletTint border-shadow p-sm-4`}
    >
      <div className='d-sm-flex flex-sm-align-center mb-sm-3'>
        <div className='w-sm-6 h-sm-6 p-sm-relative mr-sm-3 flex-sm-shrink-0'>
          {wunder.avatar.url ? (
            <Avatar src={wunder.avatar.url} />
          ) : (
            <div
              className={`circle-sm h-sm-6 w-sm-6 d-sm-flex flex-sm-align-center flex-sm-justify-center 
                flex-sm-shrink-0 f-sm-6 bg-static-${colorClass.current}
              `}
            >
              {initials}
            </div>
          )}
        </div>
        <h3 className='f-sm-2 text-bold lh-condensed'>
          {wunder.name}
          {wunder.expert ? (
            <SVGexpert
              className='vicon ml-sm-2 vertical-align-sm-middle'
              width={16}
              height={16}
            />
          ) : (
            ''
          )}
          <div className={`f-sm-1 mt-sm-1 text-regular ${styles.subtitle}`}>
            <RichText richTextContent={wunder.subtitle.json} unstyled />
          </div>
        </h3>
      </div>
      <div className={`f-sm-2 ${full ? '' : styles.testimonial}`}>
        <RichText richTextContent={wunder.testimonial.json} unstyled />
      </div>
      {!full && (
        <div style={{ marginTop: 'auto' }}>
          <button
            className='Btn-link text-underline hover-no-underline f-sm-2 mt-sm-3 primary-link stretched-link'
            type='button'
            onClick={() => {
              onClick?.()
            }}
          >
            {t('read-more')}
          </button>
        </div>
      )}
    </div>
  )
}

const customStylesMd = {
  content: {
    padding: '52px',
    borderRadius: '4px',
    borderWidth: '0',
    backgroundColor: 'var(--background-low-creamLite)',
    width: 728,
    height: 428,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 100 }
}

const customStylesSm = {
  content: {
    inset: '0',
    padding: '52px 30px',
    borderRadius: '0',
    borderWidth: '0',
    backgroundColor: 'var(--background-low-creamLite)',
    display: 'flex',
    flexDirection: 'column'
  },
  overlay: { zIndex: 100 }
}

function WunderTestimonialContent({
  unitName,
  index,
  ...wunder
}: IWunderTestimonial & { unitName: string; index: number }) {
  const [isModalOpenned, setIsModalOpenned] = useState(false)
  const isMd = useMedia({ minWidth: 768 })
  const { trackClick, trackSpEngagement } = useContext(AnalyticsContext)
  const { t } = useTranslation()
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div data-testid={`wunder-content-${index}`} className='flex-sm d-sm-flex'>
      <div className='p-sm-relative flex-sm d-sm-flex'>
        <TrackImpression
          as='div'
          className='p-sm-absolute left-sm-8'
          key={wunder.sys.id}
          componentId={unitName}
          unitName={unitName}
          unitLocation='inline'
          positionInUnit={index + 1}
          unitVariation='wunder_tip_content'
        />
        <WunderCard
          wunder={wunder}
          full={false}
          onClick={() => {
            trackClick({
              clickType: 'button',
              isContent: false,
              buttonName: 'learn_more',
              unitName,
              componentId: unitName,
              unitLocation: 'inline',
              positionInUnit: index + 1,
              unitVariation: 'wunder_tip_content',
              parentComponentId: parentComponent?.sys?.id,
              positionInParentComponent: getPositionInParent(unitName),
              isModal: true
            })
            setIsModalOpenned(true)
          }}
        />
      </div>
      <ReactModal
        isOpen={isModalOpenned}
        onRequestClose={() => setIsModalOpenned(false)}
        style={isMd ? customStylesMd : customStylesSm}
        preventScroll
      >
        <button
          aria-label={t('close-wunder-window')}
          data-testid='close-modal'
          type='button'
          onClick={() => {
            trackSpEngagement({
              eventType: 'dismiss',
              unitName: 'wunder_promo',
              componentId: unitName,
              unitLocation: 'modal',
              parentComponentId: parentComponent?.sys?.id,
              positionInParentComponent: getPositionInParent(unitName)
            })
            setIsModalOpenned(false)
          }}
          className='Btn-link p-sm-absolute right-sm-4 top-sm-4 right-md-5 top-md-5'
        >
          <SVGclose className='vicon' />
        </button>
        <div className='grid'>
          <div className='grid-col-sm-12 grid-col-md-6'>
            <WunderCard wunder={wunder} full />
          </div>
          <div
            style={{ margin: 'auto' }}
            className='grid-col-sm-12 grid-col-md-6'
          >
            <TrackImpression
              unitName='wunder_promo'
              componentId={unitName}
              unitLocation='modal'
            />
            <h1 className='f-sm-6 mb-sm-3 text-bold lh-condensed'>
              {t('download-wunder-to-read-post')}
            </h1>
            <p className='mb-sm-4 f-sm-4'>{t('join-wunder-community')}</p>
            <WunderSmartLink
              href='https://www.understood.org/wunder'
              label={t('download-the-wunder-app')}
              unitLocation='modal'
              unitName='wunder_promo'
            />
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

export default WunderTestimonialContent
