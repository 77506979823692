import { Link } from '@/journey/Link'
import { SVGborderTriangleDown } from '@/journey/Svgs/borders'
import { AnalyticsContext } from '@context/AnalyticsContext'
import RichText from '@fractal/primitives/atoms/RichText'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { useContext } from 'react'
import { getContentUrl } from '../../../utils/utils'
import { TrackImpression } from '../../TrackImpression'

const colorsMap = {
  Cream: 'background-low-cream',
  Peach: 'background-low-peachTint',
  Ochre: 'background-low-ochreTint',
  Blue: 'background-low-midblueLite',
  'Light Dark Blue': 'background-low-grey2',
  'Light Scarlet': 'background-low-peach'
}

function CarouselItemContent({ unitName, index, ...tip }: any) {
  const { trackClick } = useContext(AnalyticsContext)
  const { t } = useTranslation()
  const backgroundColor = colorsMap[tip.backgroundColor]
  const tipUrl = getContentUrl(tip)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div
      data-testid={`quick-tip-content-${index}`}
      className={`p-sm-relative d-sm-flex flex-sm-column flex-sm 
        rounded-sm-1 o-sm-hidden border-shadow bg-background-high-white
      `}
    >
      <TrackImpression
        as='div'
        className='p-sm-absolute left-sm-8'
        key={tip.sys.id}
        linkedContentId={tip.sys.id}
        linkedContentUrl={tipUrl}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={index + 1}
        unitVariation='quick_tip_content'
        isContent
        buttonText={t('read-more')}
      />
      <div>
        <h3
          className={`f-sm-4 text-bold p-sm-4 text-foreground-darkblue ${`bg-${backgroundColor}`
            }`}
        >
          <div className='f-sm-1 text-regular'>{t('quick-tip')}</div>
          {tip.title}
        </h3>
        <SVGborderTriangleDown
          pathfillColor={backgroundColor}
          id={`carousel-item-content-${tip.sys.id}`}
        />
      </div>
      <div className='p-sm-4 d-sm-flex flex-sm-column flex-sm'>
        <div className='mb-sm-3 f-sm-2'>
          <RichText richTextContent={tip.body.json} unstyled />
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Link
            href={tipUrl}
            className='f-sm-2 primary-link stretched-link'
            onClick={() => {
              trackClick({
                clickType: 'button',
                componentId: unitName,
                isContent: true,
                buttonName: null,
                buttonText: t('read-more'),
                linkedContentId: tip.sys.id,
                linkedContentUrl: tipUrl,
                unitName,
                unitLocation: 'inline',
                positionInUnit: index + 1,
                unitVariation: 'quick_tip_content',
                parentComponentId: parentComponent?.sys.id,
                positionInParentComponent: getPositionInParent(unitName)
              })
            }}
          >
            {t('read-more')}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CarouselItemContent
