import { Avatar } from '@/journey/Avatar'
import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import RichText from '@fractal/primitives/atoms/RichText'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { IPerson } from 'components/dynamic-page/shared/types/Person.type'
import { getContentUrl } from 'components/dynamic-page/shared/utils/utils'
import { useContext } from 'react'

function PersonContent({
  unitName,
  index,
  ...person
}: IPerson & { unitName: string; index: number }) {
  const { t } = useTranslation()
  const { trackClick } = useContext(AnalyticsContext)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  const positionInParentComponent = getPositionInParent(unitName)
  const personUrl = getContentUrl(person, person.typename)
  const linkText = t('view-bio')

  return (
    <div
      data-testid={`bio-card-${person.sys.id}`}
      className='p-sm-relative w-sm-full bg-background-low-white p-sm-5 pt-md-6 rounded-sm-1 border-shadow'
    >
      <TrackImpression
        className=''
        unitName={unitName}
        componentId={unitName}
        unitLocation='inline'
        positionInUnit={index}
        isContent
        linkText={linkText}
        linkedContentId={person.sys.id}
        linkedContentUrl={personUrl}
      >
        <div className='w-sm-8 h-sm-8 mx-sm-auto'>
          <Avatar
            data-testid='bio-image'
            src={person.headshot?.url}
            alt=''
            sizes='136px'
            expert={person.role === 'Expert'}
          />
        </div>
        <p className='f-sm-6 mt-sm-4 text-bold mt-md-5'>{person.fullName}</p>
        <p className='f-sm-3 f-md-4 text-bold mt-sm-2'>{person.title}</p>
        {person.shortBiography?.json && (
          <div className='f-sm-3 f-md-4 mt-sm-3 mb-sm-4'>
            <RichText richTextContent={person.shortBiography?.json} unstyled />
          </div>
        )}
        <Link
          className='primary-link stretched-link text-regular'
          href={personUrl}
          onClick={() => {
            trackClick({
              clickType: 'text',
              isContent: true,
              linkText,
              linkedContentId: person.sys.id,
              linkedContentUrl: personUrl,
              unitName,
              componentId: unitName,
              unitLocation: 'inline',
              positionInUnit: index,
              parentComponentId: parentComponent?.sys.id,
              positionInParentComponent
            })
          }}
        >
          {linkText}
        </Link>
      </TrackImpression>
    </div>
  )
}

export default PersonContent
