import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGmicrophone = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
      data-testid='microphone-svg'
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31 22.5V10.5C31 7.46243 28.5376 5 25.5 5C22.4624 5 20 7.46243 20 10.5V22.5C20 25.5376 22.4624 28 25.5 28C28.5376 28 31 25.5376 31 22.5ZM25.5 0C19.701 0 15 4.70101 15 10.5V22.5C15 28.299 19.701 33 25.5 33C31.299 33 36 28.299 36 22.5V10.5C36 4.70101 31.299 0 25.5 0Z'
      />
      <path d='M43.9991 21.5792C43.9991 20.7072 43.3038 20 42.4457 20C41.5876 20 40.8923 20.7072 40.8923 21.5792C40.8923 30.2078 33.9871 37.2279 25.4995 37.2279C17.0121 37.2279 10.1068 30.208 10.1068 21.5792C10.1068 20.7072 9.41147 20 8.55339 20C7.6953 20 7 20.7072 7 21.5792C7 31.4174 14.4694 39.5153 23.9466 40.3199V45.8412L17.0272 45.8416C16.1691 45.8416 15.4738 46.5484 15.4738 47.4208C15.4738 48.2931 16.1691 49 17.0272 49H33.9728C34.8309 49 35.5262 48.2931 35.5262 47.4208C35.5262 46.5484 34.8309 45.8416 33.9728 45.8416H27.0534V40.3202C36.5297 39.5154 44 31.4175 44 21.5786L43.9991 21.5792Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 21.5792C6 20.1707 7.12738 19 8.55339 19C9.97939 19 11.1068 20.1707 11.1068 21.5792C11.1068 29.6713 17.5799 36.2279 25.4995 36.2279C33.4194 36.2279 39.8923 29.6711 39.8923 21.5792C39.8923 20.1707 41.0197 19 42.4457 19C43.3092 19 44.0632 19.4293 44.5237 20.0807C44.5237 20.0807 45 20.7144 45 21C45 21.2856 45 21.5786 45 21.5786C45 31.6103 37.6161 39.9409 28.0534 41.2168V44.8416H33.9728C35.3987 44.8416 36.5262 46.0118 36.5262 47.4208C36.5262 48.8298 35.3987 50 33.9728 50H17.0272C15.6013 50 14.4738 48.8298 14.4738 47.4208C14.4738 46.0118 15.6013 44.8416 17.0271 44.8416L22.9466 44.8413V41.2165C13.3832 39.9409 6 31.6102 6 21.5792ZM42.9991 21.7642V21.5792C42.9991 21.2438 42.7358 21 42.4457 21C42.1555 21 41.8923 21.2438 41.8923 21.5792C41.8923 30.7444 34.5549 38.2279 25.4995 38.2279C16.4444 38.2279 9.10677 30.7446 9.10677 21.5792C9.10677 21.2438 8.84355 21 8.55339 21C8.26322 21 8 21.2438 8 21.5792C8 30.9067 15.0804 38.5636 24.0312 39.3235L24.9466 39.4012V46.8411L17.0272 46.8416C16.7369 46.8416 16.4738 47.0851 16.4738 47.4208C16.4738 47.7565 16.7368 48 17.0272 48H33.9728C34.2632 48 34.5262 47.7565 34.5262 47.4208C34.5262 47.0851 34.2632 46.8416 33.9728 46.8416H26.0534V39.4016L26.9688 39.3238C35.8593 38.5688 42.9059 31.0082 42.9991 21.7642Z'
      />
    </svg>
  )
}

export default SVGmicrophone
