import { AnalyticsContext } from '@context/AnalyticsContext'
import useTranslation from 'app/i18n/client'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import React, { useContext, useEffect, useState } from 'react'
import styles from './Truncate.module.scss'

interface TruncateProps {
  selector: string
  onContinue?: () => void
  unitName: string
}

function Truncate({
  selector,
  onContinue,
  unitName
}: TruncateProps) {
  const [maskHeight, setMaskHeight] = useState(100)
  const { t } = useTranslation()
  const { trackClick } = useContext(AnalyticsContext)
  const buttonText = t('continue-reading')
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  const onExpand = () => {
    onContinue?.()
    trackClick({
      componentId: unitName,
      unitLocation: 'inline',
      unitName,
      clickType: 'button',
      buttonName: buttonText,
      buttonText,
      parentComponentId: parentComponent?.sys.id,
      positionInParentComponent: getPositionInParent(unitName)
    })
  }

  // Ensure this runs only client side
  useEffect(() => {
    const secondParagraph = document.querySelector<HTMLParagraphElement>(
      `${selector} > p:nth-child(2)`
    )
    if (secondParagraph) setMaskHeight(secondParagraph?.offsetHeight)
  }, [])

  return (
    <>
      <div className='p-sm-relative'>
        <div
          className={`w-sm-full p-sm-absolute top-sm-0 ${styles.mask}`}
          style={{ height: `${maskHeight}px` }}
        />
      </div>
      <div className='z-index-1 text-center mt-md-3'>
        <button
          className={`Btn Btn--shadow Btn--small ${styles.button}`}
          onClick={onExpand}
          type='button'
        >
          {buttonText}
        </button>
      </div>
    </>
  )
}

export default Truncate
