import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGbook = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <path d='M27.2 6.4V1.6C27.2 0.64 26.432 0 25.6 0C25.344 0 25.088 0.064 24.832 0.192L16 5.248L7.168 0.192C6.912 0.064 6.656 0 6.4 0C5.568 0 4.8 0.64 4.8 1.6V6.4H0V32H32V6.4H27.2ZM24 4.352V6.4V9.6V22.208L17.664 27.52H17.6V9.6V8L20.416 6.4L24 4.352ZM8 4.352L11.584 6.4L14.4 8V9.6V27.52H14.336L8 22.208V9.6V6.4V4.352ZM3.2 28.8V9.6H4.8V22.976C4.8 23.424 4.992 23.872 5.376 24.192L10.88 28.8H3.2ZM28.8 28.8H21.12L26.624 24.192C27.008 23.872 27.2 23.424 27.2 22.976V9.6H28.8V28.8Z' />
    </svg>
  )
}

export default SVGbook
