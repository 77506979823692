import { ISVGsProps } from '../types'

function SVGlinkedin({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) {
  return (
    <svg
      viewBox='0 0 50 50'
      xmlns='http://www.w3.org/2000/svg'
      width={`${width}px`}
      height={`${height}px`}
      className={className}
      data-testid='linkedin-svg'
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >

      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line max-len
        d='M11.3071 49.7925H0.933635V16.39H11.3071V49.7925ZM6.12036 12.0331C2.79748 12.0331 0.10376 9.33941 0.10376 6.01654C0.10376 2.69366 2.79748 -6.10352e-05 6.12036 -6.10352e-05C9.44323 -6.10352e-05 12.137 2.69366 12.137 6.01654C12.137 7.61224 11.5031 9.14258 10.3747 10.2709C9.2464 11.3992 7.71606 12.0331 6.12036 12.0331ZM49.8963 49.7925H39.5228V33.61C39.5228 29.6681 39.5228 24.8963 34.1287 24.8963C28.7345 24.8963 27.9046 29.0457 27.9046 33.4025V50H17.5311V16.3901H27.4897V21.1618H27.6971C29.6238 17.6224 33.426 15.519 37.4482 15.7676C47.8216 15.7676 49.8963 22.6141 49.8963 31.5353V49.7925Z'
      />
    </svg>

  )
}

export default SVGlinkedin
