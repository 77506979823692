import * as React from 'react'
import { ISVGsBorderProps } from '../types'

function SVGborderTriangleUp({
  pathfillColor,
  id,
  className = ''
}: ISVGsBorderProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='7'
      aria-hidden='true'
      focusable='false'
      data-testid='border-triangleUp-svg'
      className={className || 'd-sm-block'}
    >
      <defs>
        <pattern
          id={id}
          patternUnits='userSpaceOnUse'
          width='20'
          height='7'
          fill='none'
        >
          <path
            clipRule='evenodd'
            d='M20 6.77637L10 7.67708e-05L0 6.77637H20Z'
            className={`fill-${pathfillColor}`}
          />
        </pattern>
      </defs>
      <rect width='100%' height='100%' fill={`url(#${id})`} />
    </svg>
  )
}

export default SVGborderTriangleUp
