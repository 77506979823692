import { UserContext } from '@context/UserContext'
import { addDays } from 'date-fns'
import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { IPageContext } from './PageContext.type'

export const PageContext = React.createContext<IPageContext>({
  headers: [],
  setHeaders: () => { },
  contentFilter: undefined,
  setContentFilter: () => { },
  isEmailProvided: false,
  setIsEmailProvided: () => { },
  pageLayout: undefined
})

const defaultPageLayout = {
  columnWidthSmall: 12,
  columnWidthMedium: 12,
  columnWidthLarge: 10
}

const LOCAL_STORAGE_KEY = 'dynamicPagePreferences'

export const getLocalStorageData = () => {
  const dynamicPagePreferences = localStorage.getItem(LOCAL_STORAGE_KEY)
  const dynamicPagePreferencesData = dynamicPagePreferences
    ? JSON.parse(dynamicPagePreferences)
    : undefined

  if (dynamicPagePreferencesData && dynamicPagePreferencesData.lastSaved) {
    const expirationDate = addDays(
      dynamicPagePreferencesData.lastSaved,
      14
    ).getTime()

    if (Date.now() > expirationDate) {
      localStorage.removeItem(LOCAL_STORAGE_KEY)
    } else {
      return dynamicPagePreferencesData
    }
  }

  return {
    isEmailProvided: false,
    filter: ''
  }
}

interface IProviderProps {
  children: React.ReactNode
  pageLayout: IPageContext['pageLayout']
}

export function PageProvider({
  children,
  pageLayout
}: IProviderProps) {
  const [headers, setHeaders] = useState([])
  const [contentFilter, setContentFilter] = useState()
  const [isEmailProvided, setIsEmailProvided] = useState<boolean>(false)
  const { isAuthenticated } = useContext(UserContext)

  useEffect(() => {
    const localStorageData = getLocalStorageData()
    setIsEmailProvided(localStorageData.isEmailProvided)
  }, [])

  useEffect(() => {
    if (!contentFilter && !isEmailProvided) return
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        lastSaved: Date.now(),
        filter: contentFilter,
        isEmailProvided
      })
    )
  }, [contentFilter, isEmailProvided])

  const contextValue = useMemo(
    () => ({
      headers,
      setHeaders,
      contentFilter,
      setContentFilter,
      isEmailProvided: isEmailProvided || isAuthenticated,
      setIsEmailProvided,
      pageLayout: pageLayout || defaultPageLayout
    }),
    [
      headers,
      setHeaders,
      contentFilter,
      setContentFilter,
      isEmailProvided,
      setIsEmailProvided,
      pageLayout,
      isAuthenticated
    ]
  )

  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  )
}
