import RichText from '@fractal/primitives/atoms/RichText'
import { TrackImpression } from '../../TrackImpression'

function TextCardBlock({ unitName, index, ...textBlock }: any) {
  return (
    <div
      data-testid={`text-card-block-${index}`}
      className={`p-sm-relative d-sm-flex flex-sm-column flex-sm 
        rounded-sm-1 o-sm-hidden border-shadow bg-background-high-white
      `}
    >
      <TrackImpression
        as='div'
        className='p-sm-absolute left-sm-8'
        key={textBlock.sys.id}
        linkedContentId={textBlock.sys.id}
        unitName={unitName}
        unitLocation='inline'
        componentId={unitName}
        positionInUnit={index + 1}
        unitVariation='text_card_block'
      />
      <div className='p-sm-4 d-sm-flex flex-sm-column flex-sm'>
        <div className='mb-sm-3 f-sm-2'>
          <RichText richTextContent={textBlock.content.json} unstyled />
        </div>
      </div>
    </div>
  )
}

export default TextCardBlock
