import React from 'react'
import { ITagProps } from './Tag.interface'
import { Link } from './../Link'

const Tag = ({
  children,
  href = '',
  backgroundColor = 'scarletLite',
  ...props
}: React.PropsWithChildren<ITagProps>) => {
  const classes = `f-sm-1 text-bold text-foreground-midblueShade no-underline lh-reset circle-sm d-sm-inline-flex  flex-sm-align-center px-sm-3 py-sm-2 d-sm-flex bg-background-high-${backgroundColor}`

  if (href) {
    return (
      <Link href={href} className={classes} data-testid='tag' {...props}>
        {children}
      </Link>
    )
  }

  return (
    <div className={classes} data-testid='tag' {...props}>
      {children}
    </div>
  )
}

export default Tag
