import React from 'react'
import { IPodcastProps } from './Podcast.interface'

const Podcast: React.FC<IPodcastProps> = ({
  type,
  audioId,
  darkMode,
  ...props
}) => {
  return (
    <div data-testid='podcast-player' {...props}>
      <iframe
        loading='lazy'
        height={type === 'standard' ? '200px' : '52px'}
        width='100%'
        frameBorder='no'
        scrolling='no'
        seamless
        src={`https://player.simplecast.com/${audioId}?dark=${darkMode.toString()}`}
      ></iframe>
    </div>
  )
}

Podcast.defaultProps = {
  type: 'standard',
  audioId: '22715684-b98e-4b4a-84f9-19183f81ee67',
  darkMode: false
}

export default Podcast
