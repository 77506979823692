import { gql } from '@apollo/client'
import { entryFragment } from 'components/dynamic-page/shared/types/Entry.type'

interface IImage {
  url?: string
  description?: string
  width?: number
  height?: number
}

export interface ISeoComponent {
  __typename?: string // temporary
  seoDescription?: string
  seoKeywords?: string
  seoImage?: IImage
  noIndex?: boolean
  noFollow?: boolean
}

export const infoSeoFragment = gql`
  fragment infoSeoFragment on InfoSeo {
    ...entryFragment
    seoDescription
    seoKeywords
    seoImage {
      url
      description
      width
      height
    }
    noIndex
    noFollow
  }
  ${entryFragment}
`
