import { Link } from '@/journey/Link'
import { AnalyticsContext } from '@context/AnalyticsContext'
import SVGmicrophone from '@fractal/primitives/SVGs/icons/microphone'
import { Podcast } from '@fractal/primitives/molecules/Podcast'
import { usePageComponentsContext } from 'components/dynamic-page/providers/PageComponentsContext'
import { TrackImpression } from 'components/dynamic-page/shared/components/TrackImpression'
import { useContext } from 'react'
import useDarkMode from 'use-dark-mode'
import { getContentUrl } from '../../../utils/utils'

function PodcastEpisodeContent({
  isLast,
  unitName,
  index,
  ...podcastEpisode
}: any) {
  const isDarkMode = useDarkMode()
  const { trackClick } = useContext(AnalyticsContext)
  const podcastEpisodeUrl = getContentUrl(podcastEpisode)
  const podcast = podcastEpisode.linkedFrom?.podcastCollection.items[0]
    || podcastEpisode.podcast
  const podcastUrl = getContentUrl(podcast)
  const { parent: parentComponent, getPositionInParent } = usePageComponentsContext()

  return (
    <div
      data-testid={`podcast-content-${index}`}
      className='p-sm-relative bg-background-high-ochreTint rounded-sm-1 p-sm-4 flex-sm d-sm-flex flex-sm-column'
      style={{ minHeight: 260 }}
    >
      <TrackImpression
        as='div'
        className='p-sm-absolute left-sm-8'
        key={podcastEpisode.sys.id}
        componentId={unitName}
        linkedContentId={podcastEpisode.sys.id}
        linkedContentUrl={podcastEpisodeUrl}
        unitName={unitName}
        unitLocation='inline'
        positionInUnit={index + 1}
        unitVariation='podcast_content'
        isContent
      />
      <div className='mb-sm-3 z-index-2 p-sm-relative'>
        <Link
          href={podcastUrl}
          className={`no-underline d-sm-inline-flex flex-sm-align-center f-sm-1 
            pill-sm bg-static-midblue text-static-creamLite text-bold px-sm-3 py-sm-1
          `}
          onClick={() => {
            trackClick({
              clickType: 'text',
              isContent: true,
              linkText: podcast.title,
              linkedContentId: podcast.sys.id,
              linkedContentUrl: podcastUrl,
              unitName,
              componentId: unitName,
              unitLocation: 'inline',
              unitVariation: 'podcast_content',
              parentComponentId: parentComponent?.sys.id,
              positionInParentComponent: getPositionInParent(unitName)
            })
          }}
        >
          <SVGmicrophone
            className='vicon fill-static-creamLite mr-sm-2'
            width={14}
            height={14}
          />
          {podcast.title}
        </Link>
      </div>
      <div className='mb-sm-4'>
        <Link
          href={podcastEpisodeUrl}
          className='stretched-link primary-link no-underline f-sm-5 lh-condensed'
          onClick={() => {
            trackClick({
              clickType: 'text',
              isContent: true,
              linkText: podcastEpisode.title,
              linkedContentId: podcastEpisode.sys.id,
              linkedContentUrl: podcastEpisodeUrl,
              unitName,
              componentId: unitName,
              unitLocation: 'inline',
              positionInUnit: index + 1,
              unitVariation: 'podcast_content',
              parentComponentId: parentComponent?.sys.id,
              positionInParentComponent: getPositionInParent(unitName)
            })
          }}
        >
          {podcastEpisode.title}
        </Link>
      </div>
      <div
        className='mb-sm-n2 mx-sm-n2 p-sm-relative z-index-2 no-print'
        style={{ marginTop: 'auto' }}
      >
        <Podcast
          darkMode={isDarkMode.value}
          type='mini'
          audioId={podcastEpisode.podcastId}
        />
      </div>
    </div>
  )
}

export default PodcastEpisodeContent
