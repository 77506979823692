import * as React from 'react'
import { ISVGsProps } from '../types'

const SVGmicrophone = ({
  className = '',
  width = '20',
  height = '20',
  fill = 'none'
}: ISVGsProps) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      aria-hidden='true'
      focusable='false'
      fill={fill}
    >
      <g clipPath='url(#clip0_5301_155382)'>
        <path
          d='M27.8188 14.6359C27.8188 14.0837 27.3712 13.6359 26.8188 13.6359C26.2664 13.6359 25.8188 14.0837 25.8188 14.6359C25.8188 20.0998 21.3735 24.5453 15.9094 24.5453C10.4455 24.5453 6.00004 20.0999 6.00004 14.6359C6.00004 14.0837 5.55243 13.6359 5.00002 13.6359C4.44761 13.6359 4 14.0837 4 14.6359C4 20.8658 8.80858 25.9937 14.9097 26.5032V29.9995L10.4552 29.9997C9.90276 29.9997 9.45515 30.4474 9.45515 30.9998C9.45515 31.5522 9.90276 31.9998 10.4552 31.9998H21.3643C21.9167 31.9998 22.3643 31.5522 22.3643 30.9998C22.3643 30.4474 21.9167 29.9997 21.3643 29.9997H16.9097V26.5034C23.0103 25.9938 27.8194 20.8658 27.8194 14.6355L27.8188 14.6359Z'
          strokeWidth='0.25'
        />
        <path
          d='M15.9074 21.091C19.4664 21.091 22.3617 18.1954 22.3617 14.6367V6.45487C22.3617 2.89582 19.4661 0 15.9074 0C12.3486 0 9.45312 2.89552 9.45312 6.45487V14.6367C9.45312 18.1955 12.3486 21.091 15.9074 21.091ZM11.4529 6.45427C11.4529 3.99792 13.4513 1.9995 15.9074 1.9995C18.3635 1.9995 20.3619 3.99792 20.3619 6.45427V14.6361C20.3619 17.0922 18.3635 19.0906 15.9074 19.0906C13.4513 19.0906 11.4529 17.0922 11.4529 14.6361V6.45427Z'
          strokeWidth='0.25'
        />
      </g>
      <defs>
        <clipPath id='clip0_5301_155382'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SVGmicrophone
