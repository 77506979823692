import SVGloading from '@/journey/Svgs/icons/loading'
import SVGmicrophone from '@/journey/Svgs/icons/microphone'
import SVGbook from '@fractal/primitives/SVGs/icons/book'
import SVGplay from '@fractal/primitives/SVGs/icons/play'
import { RichText } from '@fractal/primitives/atoms/RichText'
import { PageContext } from 'components/dynamic-page/providers/PageContext'
import { useContext, useEffect } from 'react'
import { IRichText } from '../../types/RichText.type'

interface ISectionHeaderProps {
  title: string
  subtitle?: IRichText
  level?: 'h2' | 'h3'
  icon?: 'watch' | 'listen' | 'read'
  isLoading?: boolean
}

const FONT_SIZE_MAP = {
  h2: 'f-sm-9 f-md-10',
  h3: 'f-sm-7 f-md-9'
}

const ICON_MAP = {
  watch: SVGplay,
  listen: SVGmicrophone,
  read: SVGbook
}

function SectionHeader({
  title,
  subtitle,
  icon,
  level = 'h2',
  isLoading
}: ISectionHeaderProps) {
  const HElement = level
  const IconElement = ICON_MAP[icon]
  const { setHeaders } = useContext(PageContext)
  const id = `title_${title?.replace(/ /g, '_')?.toLowerCase()}`

  useEffect(() => {
    if (!title) return
    setHeaders((prev) => [...prev, { id, text: title, level }])

    // eslint-disable-next-line consistent-return
    return () => {
      setHeaders((prev) => {
        const newValue = [...prev]
        const index = newValue.findIndex((h) => h.id === id)
        newValue.splice(index, 1)
        return newValue
      })
    }
  }, [id, title])

  if (!title) {
    return null
  }

  return (
    <>
      <HElement
        className={`${subtitle ? 'mb-sm-3' : 'mb-sm-5'} ${FONT_SIZE_MAP[level]
        } text-regular lh-condensed d-sm-flex flex-sm-align-center`}
        id={id}
        data-testid='section-header'
      >
        {IconElement && (
          <IconElement
            width={32}
            height={32}
            className='vicon mr-sm-3 fill-foreground-midblue'
          />
        )}
        {title}
        {!!isLoading && (
          <SVGloading
            className='vicon ml-sm-3 fill-foreground-midblue'
            width={32}
            height={32}
          />
        )}
      </HElement>
      {subtitle && (
        <div className='mb-sm-4 mb-md-5 mb-lg-6 f-sm-5 f-md-4'>
          <RichText unstyled richTextContent={subtitle.json} />
        </div>
      )}
    </>
  )
}

export default SectionHeader
