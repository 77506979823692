import ArticleContent from './ArticleContent'
import CarouselItemContent from './CarouselItemContent'
import ExternalTagContent from './ExternalTagContent'
import PageGeneric from './PageGeneric'
import PersonContent from './PersonContent'
import PodcastEpisodeCardContent from './PodcastEpisodeContent'
import TextCardBlock from './TextCardBlock'
import WunderTestimonialContent from './WunderTestimonialContent'

function MapContent({
  content,
  isVideo = false,
  unitName,
  index,
  stackOnMobile = false
}: any) {
  switch (content.typename) {
    case 'Article':
      return (
        <ArticleContent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          isVideo={isVideo}
          unitName={unitName}
          index={index}
          stackOnMobile={stackOnMobile}
        />
      )
    case 'CarouselItem':
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <CarouselItemContent {...content} unitName={unitName} index={index} />
      )
    case 'WunderTestimonial':
      return (
        <WunderTestimonialContent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )
    case 'PodcastEpisode':
      return (
        <PodcastEpisodeCardContent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )
    case 'ExternalTag':
      return (
        <ExternalTagContent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )
    case 'Person':
      return (
        <PersonContent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )
    case 'PageGeneric':
      return (
        <PageGeneric
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )
    case 'ComponentHelperTextBlock':
      return (
        <TextCardBlock
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...content}
          unitName={unitName}
          index={index}
        />
      )

    default:
      return null
  }
}

export default MapContent
